"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bike = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: {}
};
const mutations = {
    list(state, items) {
        state.items = items;
    },
};
const getters = {
    items: (state) => state.items
};
const actions = {
    async add({ commit }, data) {
        await api_js_1.default.bike.add(data.info, data.raw);
    },
    async list({ commit }, params) {
        const res = await api_js_1.default.bike.list(params);
        commit('list', res.data);
    }
};
exports.Bike = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
