"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vuex_1 = require("vuex");
vue_1.default.use(vuex_1.default);
const icons_1 = require("./modules/icons");
const media_1 = require("./modules/media");
const smoke_1 = require("./modules/smoke");
const pentomino_1 = require("./modules/pentomino");
const recipes_1 = require("./modules/recipes");
const lotto_1 = require("./modules/lotto");
const bike_1 = require("./modules/bike");
exports.default = new vuex_1.default.Store({
    modules: {
        icons: icons_1.Icons,
        media: media_1.Media,
        smoke: smoke_1.Smoke,
        pentomino: pentomino_1.Pentomino,
        recipes: recipes_1.Recipes,
        lotto: lotto_1.Lotto,
        bike: bike_1.Bike
    }
});
