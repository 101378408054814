"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lotto = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: []
};
const mutations = {
    list(state, items) {
        state.items = items;
    },
};
const getters = {
    items: (state) => state.items
};
const actions = {
    async list({ commit }) {
        const res = await api_js_1.default.tools.lotto();
        commit('list', res.data);
    }
};
exports.Lotto = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
