<template lang="pug">
.recipe(style="flex: 1 0")
  .header
    button3d(style="margin: 0.5rem", @click="goHome()")
      icon(name="home")
    div(style="flex: 1 0") {{ recipe.name }}
    button3d(
      style="margin: 0.5rem; font-size: 1.6rem",
      @click="setMode('P')",
      :class="{ selected: mode == 'P' }"
    ) Bilder
    button3d(
      style="margin: 0.5rem; font-size: 1.6rem",
      @click="setMode('I')",
      :class="{ selected: mode == 'I' }"
    ) Zutaten
    button3d(
      style="margin: 0.5rem; font-size: 1.6rem",
      @click="setMode('S')",
      :class="{ selected: mode == 'S' }"
    ) Schritte
    button3d(style="margin: 0.5rem; font-size: 1.6rem", @click="printRecipe()")
      icon(name="print")
  .info(style="display:flex;")
    .time 
      div(v-if="recipe.time",style="margin-right:0.5rem;") Zubereitungszeit:
      div {{recipe.time}}
    .size 
      div(v-if="recipe.amount",style="margin-right:0.5rem;") Menge:
      div {{recipe.amount}}
  .content
    div(
      v-if="mode == 'P'",
      style="flex: 1 0; display: flex; flex-flow: row; justify-content: stretch; padding: 1rem"
    )
      .navigator(:class="{ enabled: step > 0 }", @click="prevImage()")
        icon.giant(name="chevronLeft")
      .image(
        :style="{ backgroundImage: 'url(/data/recipes/' + (recipe.images && recipe.images.length > step && recipe.images[step]) + ')' }"
      )
      .navigator(
        :class="{ enabled: recipe.images && step < recipe.images.length - 1 }",
        @click="nextImage()"
      )
        icon.giant(name="chevronRight")
        //- img(
        //-   :src="'/data/recipes/' + i",
        //-   style="visibility: hidden; width: 10%"
        //- )
    div(
      v-if="mode == 'I'",
      style="display: flex; flex-flow: column nowrap; align-self: center; padding: 2rem 0"
    ) 
      .ing(
        v-for="(i, index) in recipe.ingredients",
        :class="{ odd: index % 2 === 1 }"
      )
        .desc {{ i.n }}
        .amount {{ i.a }}
        .unit {{ i.u }}
    div(
      v-if="mode == 'S'",
      style="flex: 1 0; display: flex; flex-flow: row; justify-content: stretch; padding: 1rem"
    ) 
      .navigator(:class="{ enabled: step > 0 }", @click="prev()")
        icon.giant(name="chevronLeft")
      .para 
        .phead1 Schritt {{ step + 1 }} von {{ steps.length }}
        .phead(v-if="steps[step].h") {{ steps[step].h }}
        div(style="flex: 1 0; display: flex")
          .img(
            v-if="steps[step].i",
            :style="{ backgroundImage: 'url(/data/recipes/' + steps[step].i + ')' }"
          )
          .pbody {{ steps[step].t }}
      .navigator(
        :class="{ enabled: step < steps.length - 1 }",
        @click="next()"
      )
        icon.giant(name="chevronRight")
  #printed.printed
    div(style="font-size: 1.5rem; color: #048") {{ recipe.name }}
    div(style="display:flex;")
      .time 
        div(v-if="recipe.time",style="margin-right:0.5rem;") Zubereitungszeit:
        div {{recipe.time}}
      .size 
        div(v-if="recipe.amount",style="margin-right:0.5rem;") Menge:
        div {{recipe.amount}}
    div(style="display: flex; margin-top: 2rem")
      div(style="flex: 1 0; display: flex; flex-flow: column")
        .image(
          :style="{ backgroundImage: 'url(/data/recipes/' + (recipe.images && recipe.images.length && recipe.images[0]) + ')' }"
        )
      div(style="flex: 1 0")
        .ing(
          v-for="(i, index) in recipe.ingredients",
          :class="{ odd: index % 2 === 1 }"
        )
          .desc {{ i.n }}
          .amount {{ i.a }}
          .unit {{ i.u }}
    div(style="margin: 1rem 0")
      .para(v-for="(s, i) in recipe.steps", :class="{ odd: i % 2 === 1 }") 
        div(style="width: 2rem; text-align: right; margin-right: 0.7rem") {{ i + 1 }}.
        div(style="flex: 1 0") {{ s.t }}
    div(style="margin:1rem 0")
      .note(v-for="n in recipe.notes") {{n}}
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
export default {
  components: { Icon, Button3d },
  //   props: ["active", "data"],
  data() {
    return {
      recipe: {},
      mode: "P",
      step: 0,
    };
  },
  computed: {
    ...mapGetters({ recipes: "recipes/items" }),
    steps() {
      return this.recipe.steps;
    },
  },
  methods: {
    printRecipe() {
      // this.$router.push({ name: "recipePrint" });

      // const routeData = this.$router.resolve({
      //   name: "recipePrint",
      //   query: { data: "someData" },
      // });
      // console.log("---", routeData);
      // window.open(routeData.href, "_blank");

      window.print();

      // var prtContent = document.getElementById("printed");
      // var WinPrint = window.open(
      //   "",
      //   "",
      //   "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      // );
      // WinPrint.document.write(prtContent.innerHTML);
      // WinPrint.document.close();
      // WinPrint.focus();
      // WinPrint.print();
      // WinPrint.close();
    },
    goHome() {
      this.$router.push({ name: "recipes" });
    },
    setMode(m) {
      this.mode = m;
      this.step = 0;
    },
    prev() {
      if (this.step > 0) this.step--;
    },
    next() {
      if (this.step < this.steps.length - 1) this.step++;
    },
    prevImage() {
      if (this.step > 0) this.step--;
    },
    nextImage() {
      if (this.step < this.recipe.images.length - 1) this.step++;
    },
  },
  mounted() {
    this.recipe = this.recipes.find((r) => r.id == this.$route.params.id);
  },
};
</script>

<style lang="less" scoped>
.recipe {
  // flex:0 0 auto;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  align-self: stretch;
  color: black;
}
.header {
  background-color: #048;
  color: white;
  font-size: 3.6rem;
  display: flex;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  align-items: center;
}
.content {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  overflow: auto;
}
  .time {
    display:flex;
    flex: 1 0;
    padding:0.5rem 1rem;
    font-size:1.5rem;
  }

  .size {
    display:flex;
    flex: 1 0;
    padding:0.5rem 1rem;
    font-size:1.5rem;
  }
.rounded {
  border: 1px solid white;
  padding: 0.3rem;
  border-radius: 1rem;
}
.navigator {
  background: #eee;
  color: #ddd;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 2rem;
}
.navigator.enabled {
  cursor: pointer;
  color: black;
  background-color: #cde;
}
.para {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  font-size: 3rem;
  align-items: flex-start;
  margin: 0 2rem;
  margin-top: 1rem;
  align-items: stretch;
}
.phead1 {
  font-weight: bold;
  font-size: 4rem;
  margin: 0.5rem 0.5rem 4rem 0.5rem;
}
.phead {
  font-weight: bold;
  margin: 0.5rem 1rem;
}
.pbody {
  flex: 2 0;
  margin: 0.5rem 3rem;
  text-align: left;
}
.image {
  margin: 0.5rem;
  width: 95%;
  height: 90%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.ing {
  display: flex;
  font-size: 2.8rem;
  margin: 0.2rem 1rem;
  padding: 0.2rem 1rem;
}
.desc {
  flex: 1 0;
  text-align: left;
  margin-right: 3rem;
  // width:24rem;
}
.amount {
  text-align: right;
  margin-right: 0.3rem;
  width: 7rem;
}
.unit {
  width: 5rem;
  color: gray;
  text-align: left;
}

.img {
  flex: 1 0;
  // width:30%;
  // height:90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
}

.odd {
  background-color: #f0f4f0;
}

.printed {
  display: none;
}

@media screen and (orientation: portrait) {
  .image {
    width: 90%;
    height: 45%;
  }
}

@media print {
  // @media (max-width: 1200px) {
  .header,
  .info,
  .content {
    display: none !important;
  }

  .recipe {
    flex: 0 0 auto;
    visibility: visible;
    margin-left: 4rem;
  }

  .time {
    font-size:0.8rem;
    display:flex;
    flex: 1 0;
    padding:0.5rem 1rem;
  }

  .size {
    font-size:0.8rem;
    display:flex;
    flex: 1 0;
    padding:0.5rem 1rem;
  }

  .printed {
    visibility: visible;
    display: block;
    width: 100%;
    // position:relative;
    // display:flex;
    // flex-flow: column nowrap;
    // align-items: flex-start;
    // break-inside: auto;
  }

  .image {
    flex: 1 0;
    // width: 100%;
    // height: 100%;
  }

  .ing,
  .para {
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.9rem;
    margin: 0 0;
    padding: 0.2rem 0.5rem;
    // break-after:always;
    break-after: auto;
    text-align: left;
  }

  .desc {
    padding-right: 0.5rem;
    margin-right: 0;
  }

  .amount {
    text-align: right;
    margin-right: 0.2rem;
    width: 2.5rem;
  }
  .unit {
    width: 2rem;
    color: gray;
    text-align: left;
  }
  .note {
    font-size:0.8rem;
    color:#048;
    text-align: left;
    margin:1rem 0;
  }
}
</style>