<template lang="pug">
.ergometer-data
  div(style="display: flex; align-items: center")
    h2(style="flex: 1 0") Ergometer X150
    icon.framed.btn(
      name="em",
      :class="{ pressed: person == 'em', disabled: isConnected }",
      @click="!isConnected && select('em')"
    )
    icon.framed.btn(
      name="gg",
      :class="{ pressed: person == 'gg', disabled: isConnected }",
      @click="!isConnected && select('gg')"
    )
    icon.framed.btn(
      :class="{ pressed: isConnected, disabled: isConnected }",
      name="bluetooth",
      @click="connectToErgometer()"
    ) 
  div(style="display: flex; flex-flow: column")
    div(
      style="flex: 0 0 auto; display: flex; flex-flow: column; align-items: center"
    )
      //- canvas#c1(width="1000",height="520",style="background-color:#eee;")
      svg#svg(
        xmlns:xlink="http://www.w3.org/1999/xlink",
        xmlns="http://www.w3.org/2000/svg",
        version="1.1",
        width="100%",
        height="100%",
        viewBox="0 0 1000 660"
      )
        g#back
          path(
            d="M 100 520 A 400 420 0 0 1 900 520",
            stroke-width="25",
            stroke="silver",
            fill="white"
          )
          path(
            d="M 90 520 A 400 420 0 0 1 910 520",
            stroke-width="2",
            stroke="black",
            fill="none"
          )
          path(d="M 90 520 L 500 560 910 520 z", fill="white")
          path(d="M 90 520 L 500 560 910 520", fill="none", stroke="black")
        g#speed
          text(
            x="500",
            y="400",
            text-anchor="middle",
            alignment-baseline="central",
            font-size="100"
          )
            tspan#cspeed
            tspan(font-size="70", dx="10") km/h
        g#needle
          path(
            d="M 530 500 Q 530 520 500 520 L 160 510 145 500 160 490 500 480 Q 530 480 530 500 z",
            fill="gray",
            stroke-width="4",
            stroke="black"
          )
      .grid
        v-display(name="Strecke", :value="info.dist.toFixed(3)", unit="km")
        v-display(
          name="Strecke gesamt",
          :value="info.distTotal.toFixed(1)",
          unit="km"
        )
        v-display(name="Zeit", :value="makeTime(info.time)", unit="")
        v-display(
          name="Zeit gesamt",
          :value="makeTime(info.timeTotal)",
          unit=""
        )
        v-display(name="Puls", :value="info.heartrate", unit="/Min")
        v-display(name="Leistung", :value="info.power", unit="W")
        v-display(name="Energie", :value="info.energy", unit="kCal")
        v-display(name="Takt", :value="info.cadence", unit="/Min")

  div(style="font-size: 1.2rem; color: red") {{ error }}
</template>
 
<script>
// import { de } from 'date-fns/locale';
import { mapActions } from "vuex";
// import * as bike from "./bike.json";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import VDisplay from "@/components/Display.vue";
export default {
  components: { Button3d, Icon, VDisplay },
  data() {
    return {
      isConnected: false,
      person: "em",
      distStart: 0,
      timeStart: 0,
      energyStart: 0,
      device: null,
      server: null,
      service: null,
      info: { dist: 0, distTotal: 0, time: 0, timeTotal: 0 },
      error: null,
      status: null,
      wakelock: null,
      wakelockIntervall: null,
    };
  },
  mounted() {
    this.init();
    this.createGauge();

    if (navigator.wakeLock) {
      this.requestWakeLock();
      this.wakeLockInterval = setInterval(() => {
        this.requestWakeLock();
      }, 1 * 60 * 1000);
    }

    // Listener für Sichtbarkeitsänderungen
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    // this.index = 0;
    // this.timer = setInterval(() => {
    //   const arr = this.json[this.index];

    //   const d = new Uint8Array();
    //   const b = new ArrayBuffer(arr.length);
    //   const a = new DataView(b);
    //   for (let i = 0; i < arr.length; i++) a.setInt8(i, arr[i]);
    //   this.parse(a);
    //   this.index++;
    //   if (this.index >= this.json.length) this.index = 0;
    // }, 360);
  },
  beforeUnmount() {
    // Wake Lock freigeben, wenn die Komponente unmountet wird
    this.releaseWakeLock();

    if (this.wakeLockInterval) {
      clearInterval(this.wakeLockInterval);
    }
    // Listener entfernen
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },
  methods: {
    ...mapActions({
      add: "bike/add",
    }),
    select(p) {
      this.person = p;
    },
    init() {
      this.info = {
        speed: 0,
        dist: 0,
        distTotal: 0,
        time: 0,
        timeTotal: 0,
        heartrate: 0,
        power: 0,
        energy: 0,
        energyTotal: 0,
        cadence: 0,
      };
    },
    async requestWakeLock() {
      try {
        // Wake Lock anfordern
        this.wakeLock = await navigator.wakeLock.request("screen");
        console.log("Wake Lock aktiviert");

        // Listener hinzufügen, falls die Wake Lock unterbrochen wird
        this.wakeLock.addEventListener("release", () => {
          console.log("Wake Lock freigegeben");
          this.wakeLock = null;
        });
      } catch (err) {
        console.error(`Wake Lock Fehler: ${err.name}, ${err.message}`);
      }
    },
    releaseWakeLock() {
      if (this.wakeLock) {
        this.wakeLock.release().then(() => {
          console.log("Wake Lock manuell freigegeben");
          this.wakeLock = null;
        });
      }
    },
    svgElement(name, options) {
      var e = document.createElementNS("http://www.w3.org/2000/svg", name);
      Object.keys(options).forEach((k) =>
        e.setAttributeNS(null, k, options[k])
      );
      return e;
    },
    createGauge() {
      const svg = document.getElementById("svg");
      const back = document.getElementById("back");

      const maxSpeed = 40;
      const diff = 5;
      const cnt = Math.trunc(maxSpeed / diff);
      for (let i = 0; i <= cnt; i++) {
        const c = Math.cos((i * Math.PI) / cnt);
        const s = Math.sin((i * Math.PI) / cnt);

        const newText = this.svgElement("text", {
          x: 500 - 450 * c,
          y: 500 - 450 * s,
          "font-size": 60,
          "text-anchor": "middle",
          "alignment-baseline": "central",
          fill: "black",
        });
        var textNode = document.createTextNode(i * diff);
        newText.appendChild(textNode);
        back.appendChild(newText);

        const path = this.svgElement("path", {
          d: `M ${500 - 340 * c} ${500 - 340 * s} L ${500 - 380 * c} ${
            500 - 380 * s
          } z`,
          stroke: "gray",
          "stroke-width": 5,
        });
        back.appendChild(path);
        if (i < cnt) {
          for (let j = 1; j < diff; j++) {
            const c = Math.cos(((i * diff + j) * Math.PI) / maxSpeed);
            const s = Math.sin(((i * diff + j) * Math.PI) / maxSpeed);
            const path = this.svgElement("path", {
              d: `M ${500 - 370 * c} ${500 - 370 * s} L ${500 - 380 * c} ${
                500 - 380 * s
              } z`,
              stroke: "silver",
              "stroke-width": 5,
            });
            back.appendChild(path);
          }
        }
      }
    },
    async connectToErgometer() {
      try {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ name: "FIT-B0D9" }],
          optionalServices: [0x1826],
        });

        this.device = device;
        this.device.addEventListener(
          "gattserverdisconnected",
          this.onDisconnected
        );

        const server = await device.gatt.connect();
        this.server = server;

        const fitnessService = await server.getPrimaryService(0x1826);

        const indoorBikeDataCharacteristic =
          await fitnessService.getCharacteristic(0x2ad2);
        indoorBikeDataCharacteristic.addEventListener(
          "characteristicvaluechanged",
          this.handleBikeDataChanged
        );
        await indoorBikeDataCharacteristic.startNotifications();

        const bt1 = await fitnessService.getCharacteristic(0x2ace);
        bt1.addEventListener("characteristicvaluechanged", this.handleBT1);
        await bt1.startNotifications();

        // clearInterval(this.timer);
        this.isConnected = true;
        this.init();
      } catch (error) {
        this.error = error;
        console.error("Bluetooth-Verbindung fehlgeschlagen:", error);
      }
    },
    v16(a, i) {
      return a.byteLength <= i ? 0 : a.getUint8(i) + a.getUint8(i + 1) * 256;
    },
    v32(a, i) {
      return (
        ((a.getUint8(i + 3) * 256 + a.getUint8(i + 2)) * 256 +
          a.getUint8(i + 1)) *
          256 +
        a.getUint8(i)
      );
    },
    makeTime(t) {
      const m = Math.trunc(t / 60);
      const s = t - m * 60;
      return ("00" + m).slice(-2) + ":" + ("00" + s).slice(-2);
    },
    dv2s(a) {
        let s = [];
        for (let i=0; i<a.byteLength; i++) {
            s.push(('0' + a.getUint8(i).toString(16)).slice(-2));
        }
        return s.join(',');
    },
    handleBT1(event) {
      const a = event.target.value;
      this.error = `${new Date().toLocaleTimeString("de-DE")} - ${this.dv2s(a)}`;
      this.parse(a);
    },
    handleBikeDataChanged(event) {
      const a = event.target.value;
      this.error = `${new Date().toLocaleTimeString("de-DE")} # ${this.dv2s(a)}`;
      this.parse(a);
      const raw = dv2s(a);
      this.add(this.info, raw);
    },
    parse(a) {
      const stamp = new Date();
      const timeTotal = a.byteLength < 17 ? this.v16(a, 15) : this.v16(a, 20);
      const person = this.person;
      const speed = this.v16(a, 3) / 100;
      const cadence = this.v16(a, 8);
      const distTotal = this.v16(a, 5) / 1000;
      const power = this.v16(a, 12);
      const energyTotal = a.byteLength <= 17 ? 0 : this.v16(a, 14);
      const heartrate = a.byteLength <= 19 ? 0 : a.getUint8(19);
      if (!this.info.distTotal) {
        this.distStart = distTotal;
        this.timeStart = timeTotal;
        this.energyStart = energyTotal;
      }
      const time = timeTotal - this.timeStart;
      const dist = distTotal - this.distStart;
      const energy = energyTotal - this.energyStart;
      this.info = {
        stamp,
        person,
        speed,
        dist: dist,
        distTotal,
        time,
        timeTotal,
        heartrate,
        power,
        energy,
        energyTotal,
        cadence,
      };
      const needle = document.getElementById("needle");
      const r = `rotate(${(speed * 180) / 30}, 500, 500)`;
      needle.setAttributeNS(null, "transform", r);
      const cspeed = document.getElementById("cspeed");
      cspeed.textContent = speed.toFixed(2) + " ";
    },
    onDisconnected() {
      console.log("Ergometer wurde getrennt.");
      this.isConnected = false;
      this.speed = null;
      this.distance = null;
    },
  },
};
</script>
 
<style scoped>
.ergometer-data {
  padding: 10px;
  display: flex;
  flex-flow: column;
  background-color: #eee;
  flex: 1 0;
}
h2 {
  color: #048;
  font-size: 2rem;
}
.grid {
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.btn {
  color: black;
  margin: 0 4px;
  box-shadow: 2px 2px 4px #000;
  padding: 1px;
}
.pressed {
  box-shadow: -2px -2px 2px #000;
  margin-top: 2px;
  margin-left: 6px;
}
.disabled {
  color: #aaa;
  box-shadow: 2px 2px 2px #999;
  border: 1px solid #999;
}
.pressed.disabled {
  box-shadow: -2px -2px 2px #999;
}
</style>

 