<template lang="pug">
div
    div(style="color:#048;font-size:1.2vh;") {{id}}
    .grid
        .cell(v-for="n in 49") {{ n }}
            div(
            v-if="crossed(n)",
            style="position: absolute; left: 0.3rem; top: 0.3rem; right: 0.3rem; bottom: 0.3rem; opacity: 0.6"
            )
                svg(
                    xmlns:xlink="http://www.w3.org/1999/xlink",
                    xmlns="http://www.w3.org/2000/svg",
                    version="1.1",
                    width="100%",
                    height="100%",
                    viewBox="0 -48 48 48"
                )
                    g(id="Layer 1", transform="scale(1 -1)")
                        path(
                        :fill="crossed(n)",
                        d="M 1.219,1.231 C -0.341,2.794 -0.338,5.329 1.225,6.888 L 18.401,24.024 L 1.225,41.161 C -0.338,42.72 -0.341,45.255 1.219,46.818 C 2.778,48.381 5.313,48.384 6.876,46.824 L 24.065,29.674 L 41.255,46.824 C 42.818,48.384 45.353,48.381 46.912,46.818 C 48.472,45.255 48.469,42.72 46.906,41.161 L 29.729,24.024 L 46.906,6.888 C 48.469,5.329 48.472,2.794 46.912,1.231 C 45.353,-0.332 42.818,-0.335 41.255,1.225 L 24.065,18.374 L 6.876,1.225 C 5.313,-0.335 2.778,-0.332 1.219,1.231 Z"
                        )
</template>

<script>
export default {
  props: ["grid","id"],
  methods: {
    crossed: function (n) {
      return this.grid && this.grid.nums.includes(n) && "#ff0000";
    },
  },
};
</script>

<style lang="less" scoped>
.grid {
    border:1px solid #048;
    padding: 1px;
    margin:0.25vh 1vh;
    flex:0 0 auto;
    color: #048;
//   align-self: center;
  font-size: 1.6vh;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  justify-content: stretch;
  column-gap: 1px;
  row-gap: 1px;
  justify-content: center;
}
.cell {
  line-height: 2vh;
  width: 2vh;
  height: 2vh;
  aspect-ratio: 1;
  padding: 0;
  margin: 0;
  border: 1px solid gray;
  border-radius: 2px;
  position: relative;
  background-color: white;
//   box-shadow: 2px 2px 5px #777 ;
//   background: linear-gradient(135deg, #fff, silver);
}
</style>