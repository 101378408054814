<template lang="pug">
.container
  h2 Lotto
  div(style="display: flex; align-items: flex-start;overflow:auto;")
    div(style="flex:0 0 auto;display:flex;flex-flow:column;")
        div(v-for="(r,rix) in rows",style="display:flex;")
            lotto-grid(v-for="(c,cix) in cols",:grid="selected[rix*cols.length+cix]",:id="rix*cols.length+cix+1")
    .panel
      button3d(style="margin:1vh 0", @click="play()")
        icon(name="play")
      button3d(style="margin:1vh 0", @click="print()")
        icon(name="print")
    .freq
        div(style="display:flex;align-items:center;",v-for="(f, ix) in freq")
            div(style="width:2rem;text-align:right;margin-right:1vh;") {{f.n}}
            .bar(:style="{ width: barwidth(f.f)}") 
                div(style="margin-left:1rem;") {{f.f}}
    .checked
        div(v-for="c in checked",style="display:flex;")
            div(style="padding:2px 1rem;") {{c.date}} 
            div(style="padding:2px 4px;width:2rem;text-align:right;color:gray;",v-for="n in c.nums") {{n}}
            div(style="padding:2px 2rem;") {{c.a.length}}
            div(style="padding:2px 4px;width:2rem;text-align:right;color:gray;",:style="{ color:color(c.a.length) }",v-for="a in c.a") {{a}}
    //- div(style="overflow:auto;font-size:1.4vh;")
        div(v-for="i in items") {{i}}
</template>

<script>
import * as moment from 'moment';
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import LottoGrid from "@/components/LottoGrid.vue";

import Vue from "vue";
export default {
  components: { Icon, Button3d, LottoGrid },
  data() {
    return {
        rows: [1,2,3,4],
        cols: [1,2],
      sum: 0,
      max: 0,
      freq: {},
      selected: [{ nums: [] }],
      checked: []
    };
  },
  computed: {
    ...mapGetters({
      items: "lotto/items",
    }),
  },
  methods: {
    ...mapActions({
      list: "lotto/list",
    }),
    color(n) {
        const c = ['','','#ccc','#c86','#864','#642','#420'];
        console.log('COLOR',n,c[n]);
        return c[n];
    },
    print() {
      window.print();
    },
    barwidth(n) {
        return 200*n/this.max + 'px';
    },
    check(sel) {
        const found = [];
        this.items.forEach(t => {
            const a = t.nums.filter(value => sel.nums.includes(value));
            if (a.length > 2) {
                found.push({ ...t, a });
            }
        })
        found.sort((a,b) => {
            const ad = moment(a.date, 'DD.MM.YYYY');
            const bd = moment(b.date, 'DD.MM.YYYY');
            console.log(ad,bd,bd.isBefore(ad));
            return ad.isBefore(bd) ? 1 : -1;
        })
        this.checked = found;
    },
    play() {
        let runs = 0;
      const s = { nums: [] };
      while (s.nums.length < 6 && runs < 10) {
        runs++;
        const r = Math.trunc(Math.random() * this.sum);
        const o = this.freq.find(f => f.s > r);
        console.log(runs, r, o);
        // while (p < r) {
        //     p += this.freq[i].f;
        //     n = 1*this.freq[i].n;
        //     i++;
        // }
        // console.log(r,p,n,i);
        if (!s.nums.includes(o.n)) 
            s.nums.push(o.n);
      }
      this.selected[0] = s;
      this.check(s);
    },
    crossed(n) {
      if (this.selected.nums.includes(n)) return "#ff0000";
      return 0;
    },
  },
  async mounted() {
    await this.list();
    this.freq = [];
    this.sum = 0;
    this.max = 0;
    const f = {};
    this.items.forEach((t) => {
      this.sum += 6;
      t.nums.forEach((n) => {
        f[n] = (f[n] || 0) + 1;
      });
    });
    const t = Object.keys(f).map(key => { return { n: 1*key, f: f[key] } });
    t.sort((a,b) => b.f-a.f);
    let s = 0;
    t.forEach(x => {
        if (x.f > this.max)
            this.max = x.f;
        s += x.f;
        x.s = s;
    })
    this.freq = t;
  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: 4vh;
  overflow: auto;
}
.freq {
    padding:0 4vh;
    display:flex;
    flex-flow:column;
    margin: 0 4vh;
  flex: 0 0 auto;
  overflow:auto;
  font-size: 1.4vh;
  color: black;
  align-self:stretch;
  align-items: flex-start;
}
.bar {
    // height:20px;
    text-align:left;
    margin:2px 0;
    padding: 4px 0;
    background-color: #88cc88;
    flex: 0 0 auto;
}
.checked {
  flex: 0 0 auto;
  overflow:auto;
  font-size: 1.8vh;
  color: black;
  align-self:stretch;
  align-items: flex-start;
}
@media print {
    .panel, .freq, .checked { display:none; }
}
</style>